"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BOOKING_INFO_BOOKING_RESULT_SAVE = exports.BOOKING_INFO_PAYMENT_RESULT_SAVE = exports.BOOKING_INFO_PAYMENT_IDLE = exports.BOOKING_INFO_PAYMENT_LOADING = exports.BOOKING_INFO_PAYMENT_INTENT_SAVE = exports.BOOKING_INFO_PAYMENT_INTENT_RESET = exports.BOOKING_INFO_REMARKS_CHANGE = exports.BOOKING_INFO_GUEST_INFO_CHANGE = exports.BOOKING_INFO_GUEST_INFO_INIT = exports.BOOKING_PREV_STEP = exports.BOOKING_NEXT_STEP = exports.BOOKING_GOTO_STEP = void 0;
var BOOKING_GOTO_STEP = "BOOKING_GOTO_STEP";
exports.BOOKING_GOTO_STEP = BOOKING_GOTO_STEP;
var BOOKING_NEXT_STEP = "BOOKING_NEXT_STEP";
exports.BOOKING_NEXT_STEP = BOOKING_NEXT_STEP;
var BOOKING_PREV_STEP = "BOOKING_PREV_STEP";
exports.BOOKING_PREV_STEP = BOOKING_PREV_STEP;
var BOOKING_INFO_GUEST_INFO_INIT = "BOOKING_INFO_GUEST_INFO_INIT";
exports.BOOKING_INFO_GUEST_INFO_INIT = BOOKING_INFO_GUEST_INFO_INIT;
var BOOKING_INFO_GUEST_INFO_CHANGE = "BOOKING_INFO_GUEST_INFO_CHANGE";
exports.BOOKING_INFO_GUEST_INFO_CHANGE = BOOKING_INFO_GUEST_INFO_CHANGE;
var BOOKING_INFO_REMARKS_CHANGE = "BOOKING_INFO_REMARKS_CHANGE";
exports.BOOKING_INFO_REMARKS_CHANGE = BOOKING_INFO_REMARKS_CHANGE;
var BOOKING_INFO_PAYMENT_INTENT_RESET = "BOOKING_INFO_PAYMENT_INTENT_RESET";
exports.BOOKING_INFO_PAYMENT_INTENT_RESET = BOOKING_INFO_PAYMENT_INTENT_RESET;
var BOOKING_INFO_PAYMENT_INTENT_SAVE = "BOOKING_INFO_PAYMENT_INTENT_SAVE";
exports.BOOKING_INFO_PAYMENT_INTENT_SAVE = BOOKING_INFO_PAYMENT_INTENT_SAVE;
var BOOKING_INFO_PAYMENT_LOADING = "BOOKING_INFO_PAYMENT_LOADING";
exports.BOOKING_INFO_PAYMENT_LOADING = BOOKING_INFO_PAYMENT_LOADING;
var BOOKING_INFO_PAYMENT_IDLE = "BOOKING_INFO_PAYMENT_IDLE";
exports.BOOKING_INFO_PAYMENT_IDLE = BOOKING_INFO_PAYMENT_IDLE;
var BOOKING_INFO_PAYMENT_RESULT_SAVE = "BOOKING_INFO_PAYMENT_RESULT_SAVE";
exports.BOOKING_INFO_PAYMENT_RESULT_SAVE = BOOKING_INFO_PAYMENT_RESULT_SAVE;
var BOOKING_INFO_BOOKING_RESULT_SAVE = "BOOKING_INFO_BOOKING_RESULT_SAVE";
exports.BOOKING_INFO_BOOKING_RESULT_SAVE = BOOKING_INFO_BOOKING_RESULT_SAVE;