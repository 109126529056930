"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateGuests = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _luxon = require("luxon");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var validateAdults = function validateAdults(adults) {
  var msgs = [];

  if (!adults[0].email) {
    msgs.push("The email is required for the group leader");
  }

  var requiredFields = ["first_name", "last_name", "title", "gender"];
  requiredFields.forEach(function (field) {
    if (adults.some(function (guest) {
      return !guest[field];
    })) {
      msgs.push("".concat(_lodash["default"].startCase(field), " is required for all adults."));
    }
  });
  return msgs;
};

var validateChildren = function validateChildren(children) {
  var msgs = [];

  if (children.some(function (child) {
    if (!child.date_of_birth) {
      return true;
    }

    if (!_luxon.DateTime.fromISO(child.date_of_birth).isValid) {
      return true;
    }

    return false;
  })) {
    msgs.push("Date of birth is required for every child.");
  }

  var requiredFields = ["first_name", "last_name", "gender"];
  requiredFields.forEach(function (field) {
    if (children.some(function (guest) {
      return !guest[field];
    })) {
      msgs.push("".concat(_lodash["default"].startCase(field), " is required for all children."));
    }
  });
  return msgs;
};

var validateGuests = function validateGuests(guests) {
  var msgs = validateAdults(guests.adults);
  msgs = _toConsumableArray(new Set([].concat(_toConsumableArray(msgs), _toConsumableArray(validateChildren(guests.children)))));
  return msgs;
};

exports.validateGuests = validateGuests;