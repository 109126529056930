import {
  Avatar,
  Icon,
  IconButton,
  List,
  Loader,
  Panel,
  PanelGroup,
} from "rsuite";

import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import BookingProcess from "../components/TourView/BookingProcess";
import sanitizeHtml from "sanitize-html";

import { TourViewHydrator } from "@filodamos/apollo_hydrator/lib/umbilical_hocs";
import {
  getAddOnImgs,
  getAddOnItemImgs,
  humanizeDuration,
} from "@filodamos/apollo_hydrator/lib/umbilical_tools";
import { createUseStyles } from "react-jss";
import { cssVariables } from "../jsssetup";
import TourSearchForm from "../components/TourView/TourSearchForm";
import MIPriceInfo from "@filodamos/apollo_hydrator/lib/components/common/MIPriceInfo";

class ItineraryItemHeader extends React.Component {
  render() {
    const { item } = this.props;
    const imgs = getAddOnItemImgs(item);
    var img = _.get(imgs, 0, "");
    if (!img) {
      img =
        "https://apollo-statics.s3.eu-central-1.amazonaws.com/images/image_placeholder.jpg";
    }

    const duration = _.get(item, "visit_duration", 0) * 60;

    return (
      <h5 className="TourView__itinerary__item__header">
        <img alt="" src={img} />
        <span>
          {_.get(item, "data.name_en", "")}
          {duration ? (
            <small>Visit Duration: {humanizeDuration({ duration })}</small>
          ) : null}
        </span>
      </h5>
    );
  }
}

ItineraryItemHeader.propTypes = {
  item: PropTypes.object.isRequired,
};

class ImgCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imgIdx: 0,
    };

    this.handleNextImg = this.handleNextImg.bind(this);
    this.handlePrevImg = this.handlePrevImg.bind(this);
  }
  componentDidUpdate(prevProps) {
    const { show, initialImgIdx } = this.props;

    if (!prevProps.show && show) {
      this.setState((p) => ({ ...p, imgIdx: initialImgIdx }));
    }
  }
  handleNextImg() {
    const { imgIdx } = this.state;
    const { imgs } = this.props;

    if (imgIdx + 1 === imgs.length) {
      this.setState((p) => ({ ...p, imgIdx: 0 }));
      return;
    }

    this.setState((p) => ({ ...p, imgIdx: this.state.imgIdx + 1 }));
  }
  handlePrevImg() {
    const { imgIdx } = this.state;
    const { imgs } = this.props;

    if (imgIdx === 0) {
      this.setState((p) => ({ ...p, imgIdx: imgs.length - 1 }));
      return;
    }
    this.setState((p) => ({ ...p, imgIdx: this.state.imgIdx - 1 }));
  }
  render() {
    const { imgIdx } = this.state;
    const { imgs, show, onHideCarousel } = this.props;

    return show ? (
      <div className="TourView TourView__img-carousel">
        <div className="TourView TourView__img-carousel__carousel">
          <IconButton
            className="TourView TourView__img-carousel__carousel__prev"
            icon={<Icon icon="angle-left" />}
            circle
            appearance="primary"
            onClick={this.handlePrevImg}
          />
          <Panel bordered>
            <img
              className="TourView TourView__img-carousel__carousel__img"
              alt=""
              src={imgs[imgIdx]}
            />
          </Panel>
          <IconButton
            className="TourView TourView__img-carousel__carousel__next"
            icon={<Icon icon="angle-right" />}
            circle
            appearance="primary"
            onClick={this.handleNextImg}
          />
          <h4 className="TourView TourView__img-carousel__carousel__idx">
            <strong>{imgIdx + 1}</strong>/<small>{imgs.length}</small>
          </h4>
          <IconButton
            className="TourView TourView__img-carousel__carousel__close-btn"
            icon={<Icon icon="close" />}
            circle
            appearance="primary"
            onClick={onHideCarousel}
          />
        </div>
      </div>
    ) : null;
  }
}

ImgCarousel.defaultProps = {
  initialIdx: 0,
};

ImgCarousel.propTypes = {
  initialIdx: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  imgs: PropTypes.array.isRequired,
  onHideCarousel: PropTypes.func.isRequired,
};

const ImgCarouselHoc = (WrappedComponent) => {
  class Comp extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showCarousel: false,
        initialImgIdx: 0,
      };

      this.handleShowCarousel = this.handleShowCarousel.bind(this);
      this.handleHideCarousel = this.handleHideCarousel.bind(this);
    }
    handleShowCarousel(initialImgIdx) {
      this.setState((p) => ({ ...p, showCarousel: true, initialImgIdx }));
    }
    handleHideCarousel() {
      this.setState((p) => ({ ...p, showCarousel: false }));
    }
    render() {
      const { showCarousel, initialImgIdx } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          showCarousel={showCarousel}
          initialImgIdx={initialImgIdx}
          onShowCarousel={this.handleShowCarousel}
          onHideCarousel={this.handleHideCarousel}
        />
      );
    }
  }

  return Comp;
};

class TItineraryItemContent extends React.Component {
  render() {
    const {
      item,
      showCarousel,
      initialImgIdx,
      onShowCarousel,
      onHideCarousel,
    } = this.props;

    var imgs = getAddOnItemImgs(item);

    if (!imgs.length) {
      imgs = [
        "https://apollo-statics.s3.eu-central-1.amazonaws.com/images/image_placeholder.jpg",
      ];
    }

    return (
      <React.Fragment>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(_.get(item, "description_en", "")),
          }}
        ></div>
        <div className="TourView__itinerary__item__carousel">
          {imgs.map((img, idx) => (
            <img
              key={idx}
              alt=""
              src={img}
              onClick={() => onShowCarousel(idx)}
            />
          ))}
        </div>
        <ImgCarousel
          imgs={imgs}
          show={showCarousel}
          initialImgIdx={initialImgIdx}
          onHideCarousel={onHideCarousel}
        />
      </React.Fragment>
    );
  }
}

TItineraryItemContent.propTypes = {
  item: PropTypes.object.isRequired,
};

const ItineraryItemContent = ImgCarouselHoc(TItineraryItemContent);

class Inclusion extends React.Component {
  render() {
    const { rule } = this.props;

    return (
      <List.Item className="TourView__inclexcl__inclusions__item">
        <h5>
          <Avatar circle size="sm">
            <Icon icon="check-circle" />
          </Avatar>
          {rule.name}
        </h5>
        {rule.description_en ? (
          <span className="TourView__inclexcl__inclusions__item__description">
            {rule.description_en}
          </span>
        ) : null}
      </List.Item>
    );
  }
}

Inclusion.propTypes = {
  rule: PropTypes.object.isRequired,
};

class Exclusion extends React.Component {
  render() {
    const { rule } = this.props;

    return (
      <List.Item className="TourView__inclexcl__exclusions__item">
        <h5>
          <Avatar circle size="sm">
            <Icon icon="close-circle" />
          </Avatar>
          {rule.name}
        </h5>
        {rule.description_en ? (
          <span className="TourView__inclexcl__exclusions__item__description">
            {rule.description_en}
          </span>
        ) : null}
      </List.Item>
    );
  }
}

Exclusion.propTypes = {
  rule: PropTypes.object.isRequired,
};

const tourDescriptionStyles = createUseStyles({
  description: {
    display: "grid",
    gridGap: cssVariables.normal_gap,
    color: cssVariables.colors.lightTextColor,
  },
  title: {
    fontSize: "xx-large",
  },
  text: {
    fontSize: "large",
  },
});

const TourDescription = (props) => {
  const { details } = props;
  const classes = tourDescriptionStyles();
  return (
    <div className={`TourView__description ${classes.description}`}>
      {details.subtitle ? (
        <h4 className={classes.title}>{details.subtitle}</h4>
      ) : null}
      <div
        className={classes.text}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            _.get(details, "general_description.description_en", "")
          ),
        }}
      ></div>
    </div>
  );
};

TourDescription.propTypes = {
  details: PropTypes.object.isRequired,
};

export const anchorBtnStyles = {
  background: cssVariables.colors.baseColor,
  color: cssVariables.colors.lightTextColor,
  padding: cssVariables.normal_gap,
  fontSize: "large",
  borderRadius: "5px",
};

const contactUsStyles = createUseStyles({
  contact: {
    display: "grid",
    justifyItems: "center",
    gridGap: cssVariables.normal_gap,
    padding: `calc(${cssVariables.normal_gap} * 4) 0`,
    margin: "5vw",
  },
  header: {
    color: cssVariables.colors.lightTextColor,
  },
  description: {
    color: cssVariables.colors.lightTextColor,
    fontSize: "large",
    textAlign: "justify",
  },
  anchor: { ...anchorBtnStyles },
  [`@media ${cssVariables.media.smallscreen}`]: {
    contact: {
      margin: cssVariables.centerMargin,
    },
  },
});

const ContactUs = (props) => {
  const { email } = props;
  const classes = contactUsStyles();

  return (
    <div className={classes.contact}>
      <h4 className={classes.header}>Contact Us</h4>
      <p className={classes.description}>
        Unable to discover the tour that suits your schedule? Our private tours
        are fully customisable to make your experience unforgettably unique.
        Reach out today, and we will do our very best to help.
      </p>
      <a className={classes.anchor} href={`mailto:${email}`}>
        <strong>Drop us an email</strong>
      </a>
    </div>
  );
};

const overlayStyles = createUseStyles({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "black",
    zIndex: 1080,
  },
});

export const Overlay = (props) => {
  const classes = overlayStyles();

  return <div className={classes.overlay}></div>;
};

class TourView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      showForm: false,
    };

    this.handleShowForm = this.handleShowForm.bind(this);
    this.handleHideForm = this.handleHideForm.bind(this);
  }
  handleShowForm() {
    this.setState((p) => ({ ...p, showForm: true }));
  }
  handleHideForm() {
    this.setState((p) => ({ ...p, showForm: false }));
  }
  getAllImgs() {
    const { details } = this.props;

    const items = _.get(details, "items", []);

    const imageUrls = _.flatten(
      items.map((item) =>
        [
          ..._.get(item, "data.custom_images", []).filter((img) => img.uuid),
          ..._.get(item, "data.image_set", []),
        ].map((img, idx) =>
          img.photo_l_url ? img.photo_l_url : img.photo_sm_url
        )
      )
    );

    return imageUrls;
  }
  renderHeader() {
    const { showForm } = this.state;
    const { details, onShowCarousel } = this.props;

    var imgs = getAddOnImgs(details);
    if (!imgs.length) {
      imgs = getAddOnImgs(details, { include_items: true });
    }

    return (
      <div className="TourView__header">
        <img alt="" src={_.get(imgs, 0, "")} />
        <h1 className="TourView__header__title">
          {_.get(details, "title", "")}
        </h1>
        <h4 className="TourView__header__destination">
          <Icon icon="map-marker" size="2x" />{" "}
          {_.get(details, "destination.name_en", "")}
        </h4>
        {!showForm ? (
          <IconButton
            className="TourView__header__bookbtn"
            appearance="primary"
            size="lg"
            icon={<Icon icon="send" />}
            placement="right"
            onClick={this.handleShowForm}
          >
            <strong>Book Now</strong>
          </IconButton>
        ) : null}
        {imgs.length > 1 ? (
          <div className="TourView__header__carousel">
            {imgs
              .filter((img, idx) => idx > 0)
              .map((img, idx) => (
                <img
                  key={idx}
                  alt=""
                  src={img}
                  onClick={function () {
                    onShowCarousel(idx);
                  }}
                />
              ))}
          </div>
        ) : null}
      </div>
    );
  }
  renderItinerary() {
    const { details } = this.props;

    var duration = "";
    if (details.approximate_duration) {
      if (details.approximate_duration.hours) {
        duration += `${details.approximate_duration.hours} Hours`;
      }

      if (details.approximate_duration.minutes > 0) {
        if (duration) {
          duration += `, ${details.approximate_duration.minutes} Minutes`;
        } else {
          duration = `${details.approximate_duration.minutes} Minutes`;
        }
      }
    }

    return (
      <div className="TourView__itinerary">
        <h4 className="TourView__itinerary__header">
          <span>Itinerary</span>
          <small className="TourView__itinerary__header__duration">
            {duration ? `Total Duration: ~${duration}` : ""}
          </small>
        </h4>
        <PanelGroup accordion defaultActiveKey={1} bordered>
          {_.get(details, "items", []).map((item, idx) => (
            <Panel
              key={idx}
              className="TourView__itinerary__item"
              header={<ItineraryItemHeader item={item} />}
              eventKey={idx + 1}
            >
              <ItineraryItemContent
                item={item}
                /* onShowCarousel={this.handleShowCarousel} */
              />
            </Panel>
          ))}
        </PanelGroup>
      </div>
    );
  }
  renderInclusionsExclusions() {
    const { details } = this.props;

    const inclusions = _.get(details, "inclusions", []).filter(
      (rule) => rule.name
    );

    const exclusions = _.get(details, "exclusions", []).filter(
      (rule) => rule.name
    );

    return (
      <div className="TourView__inclexcl">
        {inclusions.length ? (
          <div className="TourView__inclexcl__inclusions">
            <h5>Inclusions</h5>
            <List>
              {_.get(details, "inclusions", [])
                .filter((rule) => rule.name)
                .map((rule, idx) => (
                  <Inclusion key={idx} rule={rule} />
                ))}
            </List>
          </div>
        ) : null}
        {exclusions.length ? (
          <div className="TourView__inclexcl__exclusions">
            <h5>Exclusions</h5>
            <List>
              {_.get(details, "exclusions", [])
                .filter((rule) => rule.name)
                .map((rule, idx) => (
                  <Exclusion key={idx} rule={rule} />
                ))}
            </List>
          </div>
        ) : null}
      </div>
    );
  }
  renderAdditionalInfo() {
    const { details } = this.props;

    return (
      <Panel className="TourView__additionalinfo">
        <h4>Important Notes</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              _.get(details, "booking_information.description_en", "")
            ),
          }}
        ></div>
      </Panel>
    );
  }
  renderImgCarousel() {
    const { details, showCarousel, initialImgIdx, onHideCarousel } = this.props;

    var imgs = getAddOnImgs(details);
    if (!imgs.length) {
      imgs = getAddOnImgs(details, { include_items: true });
    }

    return (
      <ImgCarousel
        imgs={imgs}
        show={showCarousel}
        initialImgIdx={initialImgIdx}
        onHideCarousel={onHideCarousel}
      />
    );
  }
  render() {
    const { showForm } = this.state;
    const {
      email,
      loading,
      details,
      price_info,
      adults,
      childs,
      date,
      bookingStep,
      onResetPrice,
      onInitBooking,
    } = this.props;

    const showOverlay = showForm || !_.isEmpty(price_info);

    return (
      <div className={`TourView ${loading ? " TourView--loading" : ""}`}>
        {showOverlay ? <Overlay /> : null}
        {loading ? (
          <Loader
            className="full-screen-loader"
            center
            vertical
            content="Please wait..."
            size="lg"
          />
        ) : (
          <React.Fragment>
            {!showOverlay ? (
              <React.Fragment>
                {this.renderHeader()}
                <TourDescription details={details} />
                {this.renderItinerary()}
                {this.renderInclusionsExclusions()}
                {this.renderAdditionalInfo()}
                <ContactUs email={email} />
              </React.Fragment>
            ) : null}
            {bookingStep === 0 ? (
              !_.isEmpty(price_info) ? (
                <MIPriceInfo
                  baseColor={cssVariables.colors.baseColor}
                  background={cssVariables.colors.lightBackground}
                  price_info={price_info}
                  title={_.get(details, "title", "")}
                  adults={adults}
                  childs={childs}
                  date={date}
                  onResetPrice={onResetPrice}
                  onInitBooking={onInitBooking}
                />
              ) : (
                <TourSearchForm
                  baseColor={cssVariables.colors.baseColor}
                  background={cssVariables.colors.lightBackground}
                  show={showForm}
                  reference={_.get(details, "reference", "")}
                  product_type="MI"
                  onHide={this.handleHideForm}
                />
              )
            ) : (
              <BookingProcess
                background={cssVariables.colors.lightBackground}
                baseColor={cssVariables.colors.baseColor}
              />
            )}
            {this.renderImgCarousel()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

TourView.defaultProps = {
  loading: false,
  email: "",
};

TourView.propTypes = {
  loading: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  price_info: PropTypes.object.isRequired,
  adults: PropTypes.number.isRequired,
  childs: PropTypes.number.isRequired,
  date: PropTypes.string,
  bookingStep: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onResetPrice: PropTypes.func.isRequired,
  onInitBooking: PropTypes.func.isRequired,
};

export default TourViewHydrator(ImgCarouselHoc(TourView));
