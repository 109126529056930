import _ from "lodash";
import {
  IconButton,
  Icon,
  Panel,
  CheckPicker,
  Loader,
  SelectPicker,
} from "rsuite";

import { ListTour } from "./LandingPage";

import PropTypes from "prop-types";
import React from "react";
import { ToursHydrator } from "@filodamos/apollo_hydrator/lib/umbilical_hocs";
import { createUseStyles } from "react-jss";
import { cssVariables } from "../jsssetup";
import Splash from "@filodamos/apollo_hydrator/lib/components/common/Splash";

const tourListStyles = createUseStyles({
  description: {
    fontSize: "large",
    color: cssVariables.colors.lightTextColor,
  },
  [`@media ${cssVariables.media.smallscreen}`]: {
    description: { gridColumn: "span 3" },
  },
  [`@media ${cssVariables.media.bigscreen}`]: {
    description: { gridColumn: "span 4" },
  },
});

const TourList = (props) => {
  const { tours, tourlist_meta } = props;
  const classes = tourListStyles();
  return (
    <div className="Tours__list">
      <p
        className={classes.description}
        dangerouslySetInnerHTML={{ __html: tourlist_meta.description }}
      />
      <h3 className="Tours__list__header">{tours.length} Available Tours</h3>
      {tours.map((tour, idx) => (
        <ListTour key={idx} tour={tour} />
      ))}
    </div>
  );
};

TourList.propTypes = {
  tours: PropTypes.array.isRequired,
  tourlist_meta: PropTypes.object.isRequired,
};

class Tours extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showForm: false };
    this.handleShowForm = this.handleShowForm.bind(this);
    this.handleHideForm = this.handleHideForm.bind(this);
  }
  componentDidMount() {
    const { metaLoaded, tours, search_form, onSearch } = this.props;

    if (
      metaLoaded &&
      !tours.length &&
      !search_form.destination_code.length &&
      !search_form.categories.length
    ) {
      onSearch();
    }
  }
  componentDidUpdate(prevProps) {
    const { metaLoaded, onSearch } = this.props;

    if (!prevProps.metaLoaded && metaLoaded) {
      onSearch();
    }
  }
  handleShowForm() {
    this.setState((p) => ({ ...p, showForm: true }));
  }
  handleHideForm() {
    this.setState((p) => ({ ...p, showForm: false }));
  }
  renderHeader() {
    const { tourlist_meta } = this.props;

    return (
      <div className="Tours__header">
        <img alt="" src={_.get(tourlist_meta, "images.0.photo_xl_url")} />
        {/* <div className="Tours__header__overlay"></div> */}
        <h1>Discover the best tours in Europe</h1>
      </div>
    );
  }
  renderSearchForm() {
    const { showForm } = this.state;
    const {
      search_form,
      destinations,
      categories,
      onSearchFormChange,
      onSearch,
    } = this.props;

    return (
      <React.Fragment>
        {showForm ? (
          <div className="Tours__searchform--search-form-overlay"></div>
        ) : null}
        <Panel
          className={`Tours__searchform ${
            showForm ? "Tours__searchform--mobile-visible" : ""
          }`}
          bordered
        >
          {showForm ? (
            <IconButton
              className="Tours__searchform__closebtn"
              icon={<Icon icon="close" />}
              circle
              appearance="primary"
              onClick={this.handleHideForm}
            />
          ) : null}
          <div className="Tours__searchform__inputgroup">
            <span>
              <strong>Destination</strong>
            </span>
            <SelectPicker
              value={_.get(search_form, "destination_code.0")}
              data={destinations.map((dest) => ({
                value: dest.id,
                label: dest.name_en,
              }))}
              onChange={function (value) {
                onSearchFormChange("destination_code", [value]);
              }}
            />
          </div>
          <div className="Tours__searchform__inputgroup">
            <span>
              <strong>Tour Type</strong>
            </span>
            <CheckPicker
              value={search_form.categories}
              data={categories.map((cat) => ({
                value: cat,
                label: _.startCase(cat),
              }))}
              onChange={function (value) {
                onSearchFormChange("categories", value);
              }}
            />
          </div>
          <IconButton
            icon={<Icon icon="search" />}
            appearance="primary"
            placement="right"
            onClick={() => {
              this.handleHideForm();
              onSearch();
            }}
          >
            <strong>Search</strong>
          </IconButton>
        </Panel>
      </React.Fragment>
    );
  }
  render() {
    const { loading, tours, tourlist_meta } = this.props;

    return (
      <div className={`Tours ${loading ? "Tours--loading" : ""}`}>
        <div className="Tours__background-overlay"></div>
        {loading ? (
          <Loader
            className="full-screen-loader"
            vertical
            size="lg"
            center
            content="Please wait..."
          />
        ) : (
          <React.Fragment>
            <Splash
              marginTop="0vh"
              height="50vh"
              title={_.get(tourlist_meta, "title", "")}
              images={_.get(tourlist_meta, "images")}
              extra_titles={_.get(tourlist_meta, "extra_titles", [])}
            />
            <TourList tours={tours} tourlist_meta={tourlist_meta} />
            {this.renderSearchForm()}
            <IconButton
              className="Tours__searchbtn"
              icon={<Icon icon="search" />}
              appearance="primary"
              size="lg"
              circle
              onClick={this.handleShowForm}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

Tours.propTypes = {
  tourlist_meta: PropTypes.object.isRequired,
  tours: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  search_form: PropTypes.object.isRequired,
  metaLoaded: PropTypes.bool.isRequired,
  onSearchFormChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default ToursHydrator(Tours);
