"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPackageSearchFailedSelector = exports.destAccResultsUpdatedSelector = exports.trfIsSearchingSelector = exports.flIsSearchingSelector = exports.accIsSearchingSelector = exports.backendTransfersIsSearchingSelector = exports.backendFlightsSearchInitiatedSelector = exports.backendAccSearchInitiatedSelector = exports.pkgSearchSessionIdSelector = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _reselect = require("reselect");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getTourPriceInfo = function getTourPriceInfo(state) {
  return state.TourPriceInfo;
};

var pkgSearchSessionIdSelector = (0, _reselect.createSelector)([getTourPriceInfo], function (priceInfo) {
  return _lodash["default"].get(priceInfo, "search_data.session_id");
});
exports.pkgSearchSessionIdSelector = pkgSearchSessionIdSelector;
var backendSearchStatusesSelector = (0, _reselect.createSelector)([getTourPriceInfo], function (priceInfo) {
  return _lodash["default"].get(priceInfo, "search_data.search_statuses", {});
});

var calcBackendSearchingStatus = function calcBackendSearchingStatus(_ref) {
  var srvType = _ref.srvType,
      searchStatuses = _ref.searchStatuses;
  var srvTypes = Object.keys(searchStatuses);

  if (srvTypes.length === 0) {
    return -1;
  }

  var isSearching = 0;

  if (!srvTypes.includes(srvType)) {
    return isSearching;
  }

  var statuses = Object.values(_lodash["default"].get(searchStatuses, srvType, {}));

  if (statuses.some(function (status) {
    return status.status >= 400;
  })) {
    return -1;
  }

  isSearching = statuses.length && statuses.some(function (status) {
    return status;
  }) ? 1 : 0;
  return isSearching;
};

var backendAccSearchInitiatedSelector = (0, _reselect.createSelector)([backendSearchStatusesSelector], function (searchStatuses) {
  return calcBackendSearchingStatus({
    srvType: "accommodation",
    searchStatuses: searchStatuses
  });
});
exports.backendAccSearchInitiatedSelector = backendAccSearchInitiatedSelector;
var backendFlightsSearchInitiatedSelector = (0, _reselect.createSelector)([backendSearchStatusesSelector], function (searchStatuses) {
  return calcBackendSearchingStatus({
    srvType: "flights",
    searchStatuses: searchStatuses
  });
});
exports.backendFlightsSearchInitiatedSelector = backendFlightsSearchInitiatedSelector;
var backendTransfersIsSearchingSelector = (0, _reselect.createSelector)([backendSearchStatusesSelector], function (searchStatuses) {
  return calcBackendSearchingStatus({
    srvType: "transfers",
    searchStatuses: searchStatuses
  });
});
exports.backendTransfersIsSearchingSelector = backendTransfersIsSearchingSelector;

var calcSearchingStatus = function calcSearchingStatus(_ref2) {
  var srvType = _ref2.srvType,
      searchStatuses = _ref2.searchStatuses;
  var statuses = Object.values(_lodash["default"].get(searchStatuses, srvType, {}));

  if (!statuses.length) {
    return -1;
  }

  var isSearching = statuses.some(function (status) {
    return status;
  }) ? 1 : 0;
  return isSearching;
};

var accIsSearchingSelector = (0, _reselect.createSelector)([getTourPriceInfo], function (priceInfo) {
  return calcSearchingStatus({
    srvType: "accommodation",
    searchStatuses: _lodash["default"].get(priceInfo, "search_status", {})
  });
});
exports.accIsSearchingSelector = accIsSearchingSelector;
var flIsSearchingSelector = (0, _reselect.createSelector)([getTourPriceInfo], function (priceInfo) {
  return calcSearchingStatus({
    srvType: "flights",
    searchStatuses: _lodash["default"].get(priceInfo, "search_status", {})
  });
});
exports.flIsSearchingSelector = flIsSearchingSelector;
var trfIsSearchingSelector = (0, _reselect.createSelector)([getTourPriceInfo], function (priceInfo) {
  var searchStatuses = _lodash["default"].get(priceInfo, "search_status", {});

  var statuses = Object.entries(_lodash["default"].get(searchStatuses, "transfers", {})).map(function (entry) {
    var trfTypeObject = entry[1];
    return Object.values(trfTypeObject);
  });
  statuses = _lodash["default"].flatten(statuses);

  if (!statuses.length) {
    return -1;
  }

  var isSearching = statuses.some(function (status) {
    return status;
  }) ? 1 : 0;
  return isSearching;
});
exports.trfIsSearchingSelector = trfIsSearchingSelector;

var propsInjector = function propsInjector(_, props) {
  return props;
};

var destAccResultsUpdatedSelector = (0, _reselect.createSelector)([getTourPriceInfo, propsInjector], function (priceInfo, props) {
  var dest_order = props.dest_order;

  var status = _lodash["default"].get(priceInfo, "update_status.accommodation.".concat(dest_order), false);

  return status;
});
exports.destAccResultsUpdatedSelector = destAccResultsUpdatedSelector;
var isPackageSearchFailedSelector = (0, _reselect.createSelector)([], function () {
  return false;
});
exports.isPackageSearchFailedSelector = isPackageSearchFailedSelector;