import _ from "lodash";
export const getAddOnItemImgs = (item, {
  include_custom_images = true,
  include_stock_images = true,
  any = true,
  size = "l"
} = {}) => {
  var custom_images = [];

  if (include_custom_images || any) {
    custom_images = _.get(item, "data.custom_images", []).filter(img => img.uuid || img.uid).map(img => _.get(img, `photo_${size}_url`) || img.photo_sm_url);
  }

  var stock_images = [];

  if (include_stock_images || any) {
    stock_images = _.get(item, "data.image_set", []).filter(img => _.get(img, "enabled", true)).map(img => _.get(img, `photo_${size}_url`) || img.photo_sm_url);
  }

  if (any) {
    return [...custom_images, ...stock_images];
  } else if (include_custom_images && !include_stock_images) {
    return custom_images;
  } else if (!include_custom_images && include_stock_images) {
    return stock_images;
  } else {
    return [...custom_images, ...stock_images];
  }
};
export const getAddOnImgs = (addon, {
  include_custom_images = true,
  include_stock_images = true,
  any = true,
  include_items = false,
  size = "l"
} = {}) => {
  var imgs = _.reverse(_.sortBy(_.get(addon, "custom_images", []), "main_photo")).map(img => img[`photo_${size}_url`]);

  if (include_items == true) {
    imgs = [...imgs, ..._.flattenDeep(_.get(addon, "items", []).map(item => {
      return getAddOnItemImgs(item, {
        include_custom_images,
        include_stock_images,
        any,
        size
      });
    }))];
  }

  return imgs;
};