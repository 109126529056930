"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cssVariables = void 0;
var cssVariables = {
  normal_gap: ".75rem",
  spacing: {
    centerMargin: "0 20vw",
    centerPadding: "0 20vw"
  },
  shadows: {
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    normalShadow: "2px 2px 20px rgba(0, 0, 0, 0.2)",
    hoverShadow: "4px 4px 20px rgba(0, 0, 0, 0.4)"
  },
  media: {
    smallscreen: "screen and (min-width: 1025px)",
    bigscreen: "screen and (min-width: 1441px)",
    retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
  },
  colors: {
    lightTextColor: "#F9F8FD"
  }
};
exports.cssVariables = cssVariables;