"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PACKAGE_SERVICE_SET_SEARCH_STATUS = exports.PACKAGE_TRF_SEARCH_RESULT_SAVE = exports.PACKAGE_FLIGHT_SEARCH_RESULT_SAVE = exports.PACKAGE_ACC_POLL_SAVE = exports.LIST_ORDER_CHANGE = exports.PACKAGES_METADATA_SAVE = exports.TOURS_LIST_SET_PRODUCT_TYPE = exports.TOUR_SEARCH_INIT_SEARCH_STATUSES = exports.TOUR_SEARCH_DATA_SAVE = exports.TOUR_INIT_SEARCH_DATA_SAVE = exports.TOURS_LIST_IDLE = exports.TOURS_LIST_LOADING = exports.TOURS_LIST_SAVE = exports.TOUR_SEARCH_FORM_CHANGE = exports.TOURS_METADATA_SAVE = exports.TOUR_PRICE_INFO_RESET = exports.TOUR_PRICE_INFO_SAVE = exports.PRICE_SEARCH_FORM_IDLE = exports.PRICE_SEARCH_FORM_SEARCHING = exports.PRICE_SEARCH_FORM_CHANGE = void 0;
var PRICE_SEARCH_FORM_CHANGE = "PRICE_SEARCH_FORM_CHANGE";
exports.PRICE_SEARCH_FORM_CHANGE = PRICE_SEARCH_FORM_CHANGE;
var PRICE_SEARCH_FORM_SEARCHING = "PRICE_SEARCH_FORM_SEARCHING";
exports.PRICE_SEARCH_FORM_SEARCHING = PRICE_SEARCH_FORM_SEARCHING;
var PRICE_SEARCH_FORM_IDLE = "PRICE_SEARCH_FORM_IDLE"; // ============================== ADDONS =============================

exports.PRICE_SEARCH_FORM_IDLE = PRICE_SEARCH_FORM_IDLE;
var TOUR_PRICE_INFO_SAVE = "TOUR_PRICE_INFO_SAVE";
exports.TOUR_PRICE_INFO_SAVE = TOUR_PRICE_INFO_SAVE;
var TOUR_PRICE_INFO_RESET = "TOUR_PRICE_INFO_RESET";
exports.TOUR_PRICE_INFO_RESET = TOUR_PRICE_INFO_RESET;
var TOURS_METADATA_SAVE = "TOURS_DESTINATIONS_SAVE";
exports.TOURS_METADATA_SAVE = TOURS_METADATA_SAVE;
var TOUR_SEARCH_FORM_CHANGE = "TOUR_SEARCH_FORM_CHANGE";
exports.TOUR_SEARCH_FORM_CHANGE = TOUR_SEARCH_FORM_CHANGE;
var TOURS_LIST_SAVE = "TOURS_LIST_SAVE";
exports.TOURS_LIST_SAVE = TOURS_LIST_SAVE;
var TOURS_LIST_LOADING = "TOURS_LIST_LOADING";
exports.TOURS_LIST_LOADING = TOURS_LIST_LOADING;
var TOURS_LIST_IDLE = "TOURS_LIST_IDLE";
exports.TOURS_LIST_IDLE = TOURS_LIST_IDLE;
var TOUR_INIT_SEARCH_DATA_SAVE = "TOUR_INIT_SEARCH_DATA_SAVE";
exports.TOUR_INIT_SEARCH_DATA_SAVE = TOUR_INIT_SEARCH_DATA_SAVE;
var TOUR_SEARCH_DATA_SAVE = "TOUR_SEARCH_DATA_SAVE";
exports.TOUR_SEARCH_DATA_SAVE = TOUR_SEARCH_DATA_SAVE;
var TOUR_SEARCH_INIT_SEARCH_STATUSES = "TOUR_SEARCH_INIT_SEARCH_STATUSES"; // ============================== TOURS ==============================

exports.TOUR_SEARCH_INIT_SEARCH_STATUSES = TOUR_SEARCH_INIT_SEARCH_STATUSES;
var TOURS_LIST_SET_PRODUCT_TYPE = "TOURS_LIST_SET_PRODUCT_TYPE"; // ============================= PACKAGES ============================

exports.TOURS_LIST_SET_PRODUCT_TYPE = TOURS_LIST_SET_PRODUCT_TYPE;
var PACKAGES_METADATA_SAVE = "PACKAGES_DESTINATIONS_SAVE"; // ==================== PACKAGES & TOURS FILTERING ===================

exports.PACKAGES_METADATA_SAVE = PACKAGES_METADATA_SAVE;
var LIST_ORDER_CHANGE = "LIST_ORDER_CHANGE"; // ====================== PACKAGE DETAILS SEARCH =====================

exports.LIST_ORDER_CHANGE = LIST_ORDER_CHANGE;
var PACKAGE_ACC_POLL_SAVE = "PACKAGE_ACC_POLL_SAVE";
exports.PACKAGE_ACC_POLL_SAVE = PACKAGE_ACC_POLL_SAVE;
var PACKAGE_FLIGHT_SEARCH_RESULT_SAVE = "PACKAGE_FLIGHT_SEARCH_RESULT_SAVE";
exports.PACKAGE_FLIGHT_SEARCH_RESULT_SAVE = PACKAGE_FLIGHT_SEARCH_RESULT_SAVE;
var PACKAGE_TRF_SEARCH_RESULT_SAVE = "PACKAGE_TRF_SEARCH_RESULT_SAVE";
exports.PACKAGE_TRF_SEARCH_RESULT_SAVE = PACKAGE_TRF_SEARCH_RESULT_SAVE;
var PACKAGE_SERVICE_SET_SEARCH_STATUS = "PACKAGE_SERVICE_SET_UPDATED_STATUS";
exports.PACKAGE_SERVICE_SET_SEARCH_STATUS = PACKAGE_SERVICE_SET_SEARCH_STATUS;