"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COUNTRIES_INFO_SAVE = exports.FEATURED_ADDON_SAVE = exports.FEATURED_PACKAGE_SAVE = exports.TOUR_DATA_SAVE = exports.TOUR_IDLE = exports.TOUR_LOADING = exports.MAINPROMO_ITEMS_SAVE = exports.WEBSITEMETA_SAVE = exports.WEBSITEMETA_IDLE = exports.WEBSITEMETA_LOADING = void 0;
var WEBSITEMETA_LOADING = "WEBSITEMETA_LOADING";
exports.WEBSITEMETA_LOADING = WEBSITEMETA_LOADING;
var WEBSITEMETA_IDLE = "WEBSITEMETA_IDLE";
exports.WEBSITEMETA_IDLE = WEBSITEMETA_IDLE;
var WEBSITEMETA_SAVE = "WEBSITEMETA_SAVE";
exports.WEBSITEMETA_SAVE = WEBSITEMETA_SAVE;
var MAINPROMO_ITEMS_SAVE = "MAINPROMO_ITEMS_SAVE";
exports.MAINPROMO_ITEMS_SAVE = MAINPROMO_ITEMS_SAVE;
var TOUR_LOADING = "TOUR_LOADING";
exports.TOUR_LOADING = TOUR_LOADING;
var TOUR_IDLE = "TOUR_IDLE";
exports.TOUR_IDLE = TOUR_IDLE;
var TOUR_DATA_SAVE = "TOUR_DATA_SAVE";
exports.TOUR_DATA_SAVE = TOUR_DATA_SAVE;
var FEATURED_PACKAGE_SAVE = "FEATURED_PACKAGE_SAVE";
exports.FEATURED_PACKAGE_SAVE = FEATURED_PACKAGE_SAVE;
var FEATURED_ADDON_SAVE = "FEATURED_ADDON_SAVE";
exports.FEATURED_ADDON_SAVE = FEATURED_ADDON_SAVE;
var COUNTRIES_INFO_SAVE = "COUNTRIES_INFO_SAVE";
exports.COUNTRIES_INFO_SAVE = COUNTRIES_INFO_SAVE;