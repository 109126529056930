import _ from "lodash";
import {
  CheckPicker,
  Divider,
  Icon,
  IconButton,
  Loader,
  Panel,
  TagPicker,
  Placeholder,
} from "rsuite";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { createUseStyles } from "react-jss";

import {
  LPAboutUsHydrator,
  LPMainFormHydrator,
  LPSplashHydrator,
  MPHydrator,
} from "@filodamos/apollo_hydrator/lib/umbilical_hocs";
import {
  currencies,
  humanizeDuration,
} from "@filodamos/apollo_hydrator/lib/umbilical_tools";
import { cssVariables } from "../jsssetup";
import sanitizeHtml from "sanitize-html";

import { getAddOnImgs } from "../../../apollo_hydrator/node_modules/@filodamos/sisi_js_tools/lib/addons";
import Splash from "@filodamos/apollo_hydrator/lib/components/common/Splash";
import { anchorBtnStyles } from "./TourView";

const HydratedSplash = LPSplashHydrator(Splash);

class TMainSearchForm extends React.Component {
  renderDestinationsInput() {
    const { destinations, search_form, onChange } = this.props;

    if (!destinations.length) {
      return <Placeholder.Paragraph graph="square" active />;
    }

    return (
      <div className="LandingPage__mainsearchform__inputgroup">
        <span>
          <strong>Destination</strong>
        </span>
        <TagPicker
          size="lg"
          placement="auto"
          value={_.get(search_form, "destination_code", [])}
          data={destinations.map((dest) => ({
            label: dest.name_en,
            value: dest.id,
          }))}
          onChange={function (value) {
            onChange("destination_code", value);
          }}
        />
      </div>
    );
  }
  renderCategoriesInput() {
    const { categories, search_form, onChange } = this.props;

    if (!categories.length) {
      return <Placeholder.Paragraph graph="square" active />;
    }

    return (
      <div className="LandingPage__mainsearchform__inputgroup">
        <span>
          <strong>Tour Type</strong>
        </span>
        <CheckPicker
          size="lg"
          placement="auto"
          value={_.get(search_form, "categories", [])}
          data={categories.map((cat) => ({
            label: _.startCase(cat),
            value: cat,
          }))}
          onChange={function (value) {
            onChange("categories", value);
          }}
        />
      </div>
    );
  }
  renderSearchBtn() {
    const { destinations, history, onSearch } = this.props;

    if (!destinations.length) {
      return <Placeholder.Paragraph rows={0} graph="square" active />;
    }

    return (
      <IconButton
        icon={<Icon icon="search" />}
        appearance="primary"
        size="lg"
        onClick={() => onSearch(history)}
      >
        <strong>Search Tours</strong>
      </IconButton>
    );
  }
  render() {
    return (
      <Panel className="LandingPage__mainsearchform" bordered>
        {this.renderDestinationsInput()}
        {this.renderCategoriesInput()}
        {this.renderSearchBtn()}
      </Panel>
    );
  }
}

TMainSearchForm.propTypes = {
  history: PropTypes.object.isRequired,
  destinations: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  duration: PropTypes.object.isRequired,
  search_form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

const MainSearchForm = LPMainFormHydrator(withRouter(TMainSearchForm));

export class ListTour extends React.Component {
  renderImg() {
    const { tour } = this.props;

    const imgs = getAddOnImgs(tour, { include_items: true });
    const url = _.get(imgs, "0");
    return <img className="ListTour__img" alt="" src={url} />;
  }
  render() {
    const { tour } = this.props;

    if (!tour) {
      return null;
    }

    return (
      <Panel className="ListTour" bordered>
        {this.renderImg()}
        <div className="ListTour__content">
          <h5 className="ListTour__content__destination">
            <Icon icon="map-marker" size="2x" />
            <span>{tour.destination.name_en}</span>
          </h5>
          <div className="ListTour__content__title">
            <h5>{tour.title}</h5>
          </div>
          <p
            className="ListTour__content__description"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                _.get(tour, "general_short_description.description_en", "")
              ),
            }}
          ></p>
          <Divider />
          <div className="ListTour__content__metadata">
            <span>
              <strong>
                Approx.{" "}
                {humanizeDuration({
                  duration: _.get(tour, "approximate_duration")
                    ? _.get(tour, "approximate_duration.hours", 0) * 3600 +
                      _.get(tour, "approximate_duration.minutes", 0) * 60
                    : tour.total_duration * 60,
                })}
              </strong>
            </span>
            <span className="ListTour__content__metadata__price">
              from{" "}
              <strong>
                {currencies[tour.min_price_from.currency]}
                {tour.min_price_from.value}
              </strong>
            </span>
          </div>
        </div>
        <IconButton
          className="ListTour__bookbtn"
          icon={<Icon icon="angle-right" />}
          placement="right"
          appearance="primary"
          size="sm"
        >
          <Link className="custom-link" to={`/tour/${tour.reference}`}>
            <strong>Check Availability</strong>
          </Link>
        </IconButton>
      </Panel>
    );
  }
}

ListTour.propTypes = {
  tour: PropTypes.object.isRequired,
};

class GhostTour extends React.Component {
  render() {
    return (
      <Panel className="ListTour ListTour--ghost" bordered>
        <Placeholder.Graph graph="image" active />
        <Placeholder.Grid columns={2} rows={2} active />
        <Placeholder.Paragraph rows={4} active />
      </Panel>
    );
  }
}

const mainPromoStyles = createUseStyles({
  title: {
    textAlign: "center",
    color: cssVariables.colors.baseColor,
  },
  description: {
    textAlign: "center",
    color: cssVariables.colors.lightTextColor,
    fontSize: "large",
    margin: "0 5vw",
  },
});

const TMainPromo = (props) => {
  const { addons, title, description } = props;

  const classes = mainPromoStyles();

  return (
    <div className="LandingPage__mainpromo">
      <h2 className={classes.title}>{title}</h2>
      {description ? (
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(description),
          }}
        ></p>
      ) : null}
      <h2 className="LandingPage__mainpromo__header">Top Tours</h2>
      <div className="LandingPage__mainpromo__items LandingPage__mainpromo__items--6">
        {addons.length
          ? addons.map((item, idx) => <ListTour key={idx} tour={item} />)
          : new Array(6).fill(1).map((i, idx) => <GhostTour key={idx} />)}
      </div>
    </div>
  );
};

TMainPromo.defaultProps = {
  addons: [],
};

TMainPromo.propTypes = {
  addons: PropTypes.array.isRequired,
  description: PropTypes.string,
};

const MainPromo = MPHydrator(TMainPromo);

const aboutUsStyles = createUseStyles({
  about: {
    display: "grid",
    gridGap: cssVariables.normal_gap,
    margin: "0 5vw",
    marginTop: "5vh",
    marginBottom: "5vh",
    color: cssVariables.colors.lightTextColor,
  },
  content: {
    display: "grid",
    gridGap: `calc(${cssVariables.normal_gap} * 2)`,
  },
  header: {
    color: cssVariables.colors.baseColor,
  },
  description: {
    fontSize: "large",
    textAlign: "justify",
  },
  imgsContainer: {
    display: "grid",
    gridGap: `calc(${cssVariables.normal_gap} * 2)`,
    gridAutoRows: "min-content",
    alignContent: "center",
    "& img": {
      height: "25vh",
      width: "100%",
      objectFit: "cover",
      borderRadius: cssVariables.normal_gap,
    },
  },
  button: { ...anchorBtnStyles },
  [`@media ${cssVariables.media.smallscreen}`]: {
    about: {
      margin: "5vh 10vw",
    },
    description: {
      textAlign: "left",
    },
    button: {
      justifySelf: "start",
    },
  },
});

const TAboutUs = (props) => {
  const { about_us, email } = props;
  const classes = aboutUsStyles();
  return (
    <div className={classes.about}>
      <div className={classes.content}>
        <h2 className={classes.header}>About Us</h2>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(_.get(about_us, "description")),
          }}
        ></p>
        <a className={classes.button} href={`mailto:${email}`}>
          <strong>Contact Us</strong>
        </a>
      </div>
    </div>
  );
};

TAboutUs.defaultProps = {
  about_us: {},
  email: "",
};

TAboutUs.propTypes = {
  about_us: PropTypes.object,
  email: PropTypes.string.isRequired,
};

const AboutUs = LPAboutUsHydrator(TAboutUs);

const LandingPage = (props) => {
  const { loading } = props;

  return (
    <div className={`LandingPage ${loading ? " LandingPage--loading" : ""}`}>
      <div className="LandingPage__background-overlay"></div>
      {loading ? (
        <Loader
          className="full-screen-loader"
          center
          size="lg"
          content="Please wait..."
          vertical
        />
      ) : (
        <React.Fragment>
          <HydratedSplash />
          <MainSearchForm />
          <MainPromo />
          <AboutUs />
        </React.Fragment>
      )}
    </div>
  );
};

LandingPage.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LandingPage;
