"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getAddOnItemImgs", {
  enumerable: true,
  get: function get() {
    return _addons.getAddOnItemImgs;
  }
});
Object.defineProperty(exports, "getAddOnImgs", {
  enumerable: true,
  get: function get() {
    return _addons.getAddOnImgs;
  }
});
exports.getPkgServiceMeta = exports.currencies = exports.humanizeDuration = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _addons = require("@filodamos/sisi_js_tools/lib/addons");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var humanizeDuration = function humanizeDuration(_ref) {
  var duration = _ref.duration,
      _ref$withSeconds = _ref.withSeconds,
      withSeconds = _ref$withSeconds === void 0 ? false : _ref$withSeconds;

  if (duration < 60) {
    if (duration === 1) {
      return "1 second";
    }

    return "".concat(duration, " seconds");
  } else if (duration === 60) {
    return "1 minute";
  } else if (duration < 3600) {
    var minutes = (duration / 60).toFixed();
    var seconds = duration % 60;
    return "".concat(minutes, " min ").concat(seconds > 0 ? "".concat(seconds, " sec") : "");
  } else {
    var hours = Math.floor(duration / 3600).toFixed();

    var _minutes = Math.floor(duration % 3600 / 60).toFixed();

    var _seconds = duration % 60;

    return "".concat(hours, " hrs").concat(_minutes > 0 ? " ".concat(_minutes, " min") : "").concat(_seconds > 0 && withSeconds ? "".concat(_seconds, " sec") : "");
  }
};

exports.humanizeDuration = humanizeDuration;
var currencies = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BOV: "BOV",
  BRL: "R$",
  BSD: "$",
  BTC: "₿",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BYR: "Br",
  BZD: "BZ$",
  CAD: "$",
  CDF: "FC",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  COU: "COU",
  CRC: "₡",
  CUC: "$",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD$",
  DZD: "دج",
  EEK: "kr",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  ETH: "Ξ",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHC: "₵",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "J$",
  JOD: "JD",
  JPY: "¥",
  KES: "KSh",
  KGS: "лв",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "KD",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "M",
  LTC: "Ł",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "lei",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP$",
  MRO: "UM",
  MRU: "UM",
  MUR: "₨",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MXV: "MXV",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "₨",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "Gs",
  QAR: "﷼",
  RMB: "￥",
  RON: "lei",
  RSD: "Дин.",
  RUB: "₽",
  RWF: "R₣",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "ج.س.",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "S",
  SRD: "$",
  SSP: "£",
  STD: "Db",
  STN: "Db",
  SVC: "$",
  SYP: "£",
  SZL: "E",
  THB: "฿",
  TJS: "SM",
  TMT: "T",
  TND: "د.ت",
  TOP: "T$",
  TRL: "₤",
  TRY: "₺",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYI: "UYI",
  UYU: "$U",
  UYW: "UYW",
  UZS: "лв",
  VEF: "Bs",
  VES: "Bs.S",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "FCFA",
  XBT: "Ƀ",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  XSU: "Sucre",
  XUA: "XUA",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWD: "Z$",
  ZWL: "$"
};
exports.currencies = currencies;

var getPkgServiceMeta = function getPkgServiceMeta(pkg) {
  var flights = _lodash["default"].get(pkg, "itinerary_setup.requiredServices", []).includes("TR");

  var accNo = _lodash["default"].flatten(Object.values(_lodash["default"].get(pkg, "accommodation_data", {}))).length;

  var addOnNo = _lodash["default"].flatten(Object.values(_lodash["default"].get(pkg, "addon_data", {}))).length;

  var trfNo = _lodash["default"].flatten(Object.values(_lodash["default"].get(pkg, "transfer_data", {}))).length;

  return {
    hasFl: flights,
    accNo: accNo,
    hasAcc: accNo > 0,
    addOnNo: addOnNo,
    hasAddOn: addOnNo > 0,
    trfNo: trfNo,
    hasTrf: trfNo > 0
  };
};

exports.getPkgServiceMeta = getPkgServiceMeta;