"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CountriesInfo = exports.AutocompleteData = exports.BookingInformation = exports.ToursList = exports.TourSearchForm = exports.TourPriceInfo = exports.PriceSearchForm = exports.TourDetails = exports.FeaturedPackage = exports.MainPromoItems = exports.WebsiteMetadata = void 0;

var _immutabilityHelper = _interopRequireDefault(require("immutability-helper"));

var _uuid = require("uuid");

var _lodash = _interopRequireDefault(require("lodash"));

var _types = require("./actions/book/types");

var _types2 = require("./actions/init/types");

var _types3 = require("./actions/search/types");

var _types4 = require("./actions/common/types");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var WebsiteMetadataInitial = {
  metadata: {},
  destinations: [],
  categories: [],
  duration: [],
  loading: false,
  packagesMetadata: {}
};

var WebsiteMetadata = function WebsiteMetadata() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : WebsiteMetadataInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types2.WEBSITEMETA_SAVE:
      {
        var metadata = action.metadata;
        return _objectSpread(_objectSpread({}, state), {}, {
          metadata: metadata
        });
      }

    case _types2.WEBSITEMETA_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _types2.WEBSITEMETA_IDLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });

    case _types3.TOURS_METADATA_SAVE:
      {
        var destinations = action.destinations,
            categories = action.categories,
            duration = action.duration;
        return _objectSpread(_objectSpread({}, state), {}, {
          destinations: destinations,
          categories: categories,
          duration: duration
        });
      }

    case _types3.PACKAGES_METADATA_SAVE:
      {
        var services = action.services,
            _destinations = action.destinations,
            durations = action.durations;
        return _objectSpread(_objectSpread({}, state), {}, {
          packagesMetadata: {
            services: services,
            destinations: _destinations,
            durations: durations
          }
        });
      }

    default:
      return state;
  }
};

exports.WebsiteMetadata = WebsiteMetadata;
var MainPromoItemsInitial = {
  items: []
};

var MainPromoItems = function MainPromoItems() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : MainPromoItemsInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types2.MAINPROMO_ITEMS_SAVE:
      {
        var items = action.items;
        return _objectSpread(_objectSpread({}, state), {}, {
          items: items
        });
      }

    default:
      return state;
  }
};

exports.MainPromoItems = MainPromoItems;
var FeaturedPackageInitial = {};

var FeaturedPackage = function FeaturedPackage() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : FeaturedPackageInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types2.FEATURED_PACKAGE_SAVE:
      {
        var pkg = action.pkg;
        return _objectSpread(_objectSpread({}, state), pkg);
      }

    default:
      return state;
  }
};

exports.FeaturedPackage = FeaturedPackage;
var TourDetailsInitial = {
  details: {},
  loading: false
};

var TourDetails = function TourDetails() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : TourDetailsInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types2.TOUR_DATA_SAVE:
      {
        var details = action.details;
        return _objectSpread(_objectSpread({}, state), {}, {
          details: details
        });
      }

    case _types2.TOUR_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _types2.TOUR_IDLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });

    case _types3.PACKAGE_ACC_POLL_SAVE:
      {
        var dest_order = action.dest_order,
            data = action.data;
        return (0, _immutabilityHelper["default"])(state, {
          details: {
            accommodation_data: _defineProperty({}, dest_order.toString(), {
              $set: data
            })
          }
        });
      }

    case _types3.PACKAGE_FLIGHT_SEARCH_RESULT_SAVE:
      {
        var _dest_order = action.dest_order,
            _data = action.data;
        var orderKey = _dest_order == 0 ? "extremalLeg" : _dest_order.toString();
        return (0, _immutabilityHelper["default"])(state, {
          details: {
            transportation_data: {
              flights: _defineProperty({}, orderKey, {
                $set: [_data]
              })
            }
          }
        });
      }

    case _types3.PACKAGE_TRF_SEARCH_RESULT_SAVE:
      {
        var _dest_order2 = action.dest_order,
            leg_type = action.leg_type,
            _data2 = action.data;
        return (0, _immutabilityHelper["default"])(state, {
          details: {
            transfer_data: _defineProperty({}, _dest_order2.toString(), _defineProperty({}, leg_type, {
              $set: _data2
            }))
          }
        });
      }

    case _types2.WEBSITEMETA_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    default:
      return state;
  }
};

exports.TourDetails = TourDetails;
var roomTemplate = {
  adults: 2,
  children: 0,
  children_ages: []
};
var PriceSearchFormInitial = {
  date: null,
  adults: _lodash["default"].clone(roomTemplate.adults),
  children: 0,
  searching: false,
  rooms: [_objectSpread({}, roomTemplate)],
  flight_origin: ""
};

var PriceSearchForm = function PriceSearchForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : PriceSearchFormInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types3.PRICE_SEARCH_FORM_CHANGE:
      {
        var key = action.key,
            value = action.value;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, key, value));
      }

    case _types3.PRICE_SEARCH_FORM_SEARCHING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searching: true
        });
      }

    case _types3.PRICE_SEARCH_FORM_IDLE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          searching: false
        });
      }

    default:
      return state;
  }
};

exports.PriceSearchForm = PriceSearchForm;
var TourPriceInfoInitial = {
  price_info: {},
  search_session_info: {},
  search_data: {},
  search_status: {},
  update_status: {} // Tracks if results for a specific service have been updated

};

var TourPriceInfo = function TourPriceInfo() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : TourPriceInfoInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types3.TOUR_PRICE_INFO_SAVE:
      {
        var price_info = action.price_info;
        return _objectSpread(_objectSpread({}, state), {}, {
          price_info: price_info
        });
      }

    case _types3.TOUR_PRICE_INFO_RESET:
      return TourPriceInfoInitial;

    case _types3.TOUR_INIT_SEARCH_DATA_SAVE:
      {
        var session_id = action.session_id;
        return _objectSpread(_objectSpread({}, state), {}, {
          search_data: _objectSpread(_objectSpread({}, state.search_data), {}, {
            session_id: session_id
          })
        });
      }

    case _types3.TOUR_SEARCH_DATA_SAVE:
      {
        var search_data = action.search_data;
        return _objectSpread(_objectSpread({}, state), {}, {
          search_data: _objectSpread(_objectSpread({}, state.search_data), search_data)
        });
      }

    case _types3.TOUR_SEARCH_INIT_SEARCH_STATUSES:
      {
        var srvs = action.srvs;
        var search_status = {};
        srvs.forEach(function (srv) {
          return search_status[srv] = {};
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          search_status: search_status
        });
      }

    case _types3.PACKAGE_ACC_POLL_SAVE:
      {
        var dest_order = action.dest_order,
            status = action.status;
        return (0, _immutabilityHelper["default"])(state, {
          search_status: _defineProperty({}, "accommodation", {
            $set: _defineProperty({}, dest_order.toString(), status)
          }),
          update_status: _defineProperty({}, "accommodation", {
            $set: _defineProperty({}, dest_order.toString(), true)
          })
        });
      }

    case _types3.PACKAGE_FLIGHT_SEARCH_RESULT_SAVE:
      {
        var _dest_order3 = action.dest_order,
            _status = action.status;
        return (0, _immutabilityHelper["default"])(state, {
          search_status: _defineProperty({}, "flights", {
            $set: _defineProperty({}, _dest_order3.toString(), _status)
          }),
          update_status: _defineProperty({}, "flights", {
            $set: _defineProperty({}, _dest_order3.toString(), true)
          })
        });
      }

    case _types3.PACKAGE_SERVICE_SET_SEARCH_STATUS:
      {
        var srvType = action.srvType,
            _dest_order4 = action.dest_order,
            _status2 = action.status;
        return (0, _immutabilityHelper["default"])(state, {
          search_status: _defineProperty({}, srvType, {
            $set: _defineProperty({}, _dest_order4.toString(), _status2)
          })
        });
      }

    case _types3.PACKAGE_TRF_SEARCH_RESULT_SAVE:
      {
        var _dest_order5 = action.dest_order,
            leg_type = action.leg_type,
            _status3 = action.status;
        return (0, _immutabilityHelper["default"])(state, {
          search_status: _defineProperty({}, "transfers", {
            $apply: function $apply(transfers) {
              return _objectSpread(_objectSpread({}, transfers), {}, _defineProperty({}, _dest_order5.toString(), _objectSpread(_objectSpread({}, _lodash["default"].get(transfers, _dest_order5.toString(), {})), {}, _defineProperty({}, leg_type, _status3))));
            }
          })
        });
      }

    default:
      return state;
  }
};

exports.TourPriceInfo = TourPriceInfo;
var TourSearchFormInitial = {
  destination_code: [],
  categories: [],
  services: [],
  durations: []
};

var TourSearchForm = function TourSearchForm() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : TourSearchFormInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types3.TOUR_SEARCH_FORM_CHANGE:
      {
        var key = action.key,
            value = action.value;
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, key, value));
      }

    default:
      return state;
  }
};

exports.TourSearchForm = TourSearchForm;
var ToursListInitial = {
  list: [],
  product_type: "PKG",
  loading: false,
  ordering: "price_low_to_high"
};

var ToursList = function ToursList() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ToursListInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types3.TOURS_LIST_SET_PRODUCT_TYPE:
      {
        var product_type = action.product_type;
        return _objectSpread(_objectSpread({}, state), {}, {
          list: [],
          product_type: product_type
        });
      }

    case _types3.TOURS_LIST_SAVE:
      {
        var list = action.list,
            _product_type = action.product_type;
        return _objectSpread(_objectSpread({}, state), {}, {
          list: list,
          product_type: _product_type
        });
      }

    case _types3.TOURS_LIST_IDLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });

    case _types3.TOURS_LIST_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _types2.WEBSITEMETA_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _types3.LIST_ORDER_CHANGE:
      {
        var ordering = action.ordering;
        return _objectSpread(_objectSpread({}, state), {}, {
          ordering: ordering
        });
      }

    default:
      return state;
  }
};

exports.ToursList = ToursList;
var guestTemplate = {
  title: "",
  first_name: "",
  last_name: "",
  email: "",
  group_leader: "",
  room_leader: "",
  gender: "",
  date_of_birth: ""
};
var BookingInformationInitial = {
  guests: {},
  remarks: "",
  execution_date: "",
  tour_reference: null,
  step: 0,
  paymentIntent: {},
  paymentResult: {},
  bookingResult: {},
  loading: false,
  pickup: {
    name: ""
  },
  address: ""
};

var BookingInformation = function BookingInformation() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : BookingInformationInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // When tour details are loaded reset the bookingprocess
    case _types2.TOUR_DATA_SAVE:
      return BookingInformationInitial;

    case _types.BOOKING_PREV_STEP:
      return _objectSpread(_objectSpread({}, state), {}, {
        step: state.step - 1
      });

    case _types.BOOKING_GOTO_STEP:
      {
        var step = action.step;
        state = _objectSpread(_objectSpread({}, state), {}, {
          step: step
        });

        if (step === 2) {
          state = _objectSpread(_objectSpread({}, state), {}, {
            pickup: {
              name: ""
            },
            address: ""
          });
        }

        return state;
      }

    case _types.BOOKING_INFO_GUEST_INFO_INIT:
      {
        var adults = action.adults,
            children = action.children;
        return _objectSpread(_objectSpread({}, state), {}, {
          remarks: "",
          pickup: {
            name: ""
          },
          address: "",
          guests: {
            adults: new Array(adults).fill(1).map(function (id, idx) {
              var g = _lodash["default"].cloneDeep(_lodash["default"].omit(guestTemplate, "date_of_birth"));

              return _objectSpread(_objectSpread({}, idx === 0 ? _objectSpread(_objectSpread({}, g), {}, {
                group_leader: true,
                room_leader: true
              }) : _objectSpread(_objectSpread({}, g), {}, {
                group_leader: false,
                room_leader: false
              })), {}, {
                uid: (0, _uuid.v4)(),
                guest_type: "ADT"
              });
            }),
            children: new Array(children).fill(1).map(function () {
              return _objectSpread(_objectSpread({}, _lodash["default"].omit(_lodash["default"].cloneDeep(guestTemplate), ["email", "room_leader", "group_leader", "title"])), {}, {
                uid: (0, _uuid.v4)(),
                guest_type: "CHD"
              });
            })
          }
        });
      }

    case _types.BOOKING_INFO_GUEST_INFO_CHANGE:
      {
        var uid = action.uid,
            formValue = action.formValue,
            travellerType = action.travellerType;
        var gKey = travellerType === "adult" ? "adults" : "children";
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "guests", _objectSpread(_objectSpread({}, state.guests), {}, _defineProperty({}, gKey, state.guests[gKey].map(function (guest) {
          return guest.uid === uid ? formValue : _objectSpread({}, guest);
        })))));
      }

    case _types.BOOKING_INFO_REMARKS_CHANGE:
      {
        var remarks = action.remarks,
            pickup = action.pickup,
            address = action.address;
        return _objectSpread(_objectSpread({}, state), {}, {
          remarks: remarks,
          pickup: pickup,
          address: address
        });
      }

    case _types.BOOKING_INFO_PAYMENT_INTENT_SAVE:
      {
        var paymentIntent = action.paymentIntent;
        return _objectSpread(_objectSpread({}, state), {}, {
          paymentIntent: paymentIntent
        });
      }

    case _types.BOOKING_INFO_PAYMENT_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case _types.BOOKING_INFO_PAYMENT_IDLE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case _types.BOOKING_INFO_PAYMENT_INTENT_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentIntent: {},
        paymentResult: {}
      });

    case _types.BOOKING_INFO_PAYMENT_RESULT_SAVE:
      {
        var paymentResult = action.paymentResult;
        return _objectSpread(_objectSpread({}, state), {}, {
          paymentResult: paymentResult
        });
      }

    case _types.BOOKING_INFO_BOOKING_RESULT_SAVE:
      {
        var bookingResult = action.bookingResult;
        console.log(bookingResult);
        return _objectSpread(_objectSpread({}, state), {}, {
          bookingResult: bookingResult
        });
      }

    default:
      return state;
  }
};

exports.BookingInformation = BookingInformation;
var AutocompleteDataInitial = [];

var AutocompleteData = function AutocompleteData() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : AutocompleteDataInitial;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types4.AUTOCOMPLETE_RESET_DATA:
      return AutocompleteDataInitial;

    case _types4.AUTOCOMPLETE_SAVE_DATA:
      {
        var data = action.data;
        return data;
      }

    default:
      return state;
  }
};

exports.AutocompleteData = AutocompleteData;

var CountriesInfo = function CountriesInfo() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _types2.COUNTRIES_INFO_SAVE:
      {
        var country_info = action.country_info;
        return _objectSpread(_objectSpread({}, state), country_info);
      }

    default:
      return state;
  }
};

exports.CountriesInfo = CountriesInfo;