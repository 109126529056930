"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  dayByDayDataSelector: true,
  topCountriesMetaSelector: true,
  getToursList: true,
  tripServicesListSelector: true,
  pkgTransfersRequiredSelector: true,
  getProductCategoriesSelector: true
};
exports.getProductCategoriesSelector = exports.pkgTransfersRequiredSelector = exports.tripServicesListSelector = exports.getToursList = exports.topCountriesMetaSelector = exports.dayByDayDataSelector = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _reselect = require("reselect");

var _pkg_price_search_selectors = require("./pkg_price_search_selectors");

Object.keys(_pkg_price_search_selectors).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _pkg_price_search_selectors[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _pkg_price_search_selectors[key];
    }
  });
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var propsInjector = function propsInjector(state, props) {
  return props;
};

var getTourDetails = function getTourDetails(state) {
  return _lodash["default"].get(state, "TourDetails.details", {});
};

var tourIsRoundtripSelector = (0, _reselect.createSelector)([getTourDetails], function (tourDetails) {
  var isRoundtrip = _lodash["default"].get(tourDetails, "destinations.0.name", "") === _lodash["default"].get(_lodash["default"].last(_lodash["default"].get(tourDetails, "destinations", [])), "name", "");

  return isRoundtrip;
});

var getStartDay = function getStartDay(order, details) {
  var prevDests = _lodash["default"].get(details, "destinations", []).filter(function (pdest) {
    return parseInt(pdest.order, 10) < order;
  });

  var startDay = prevDests.reduce(function (a, b) {
    return a + b.stay;
  }, 1);
  return startDay;
};

var setDays = function setDays(stay, startDay) {
  var days = new Array(stay + 1).fill(1).map(function (i, idx) {
    return startDay + idx;
  });
  return days;
};

var updateDaysDataObject = function updateDaysDataObject(object, daysObject) {
  object["days"].forEach(function (day) {
    daysObject[day] = [].concat(_toConsumableArray(_lodash["default"].get(daysObject, day, [])), [object]);
  });
};

var getDestDaysData = function getDestDaysData(details) {
  var destDaysData = {};

  _lodash["default"].get(details, "destinations", []).forEach(function (dest) {
    var startDay = getStartDay(dest.order, details);
    dest["days"] = setDays(dest.stay, startDay);
    dest["events"] = {};
    dest.days.forEach(function (day, idx) {
      if (idx === 0) {
        dest.events[day] = "arrival";
      } else if (idx + 1 < dest.days.length) {
        dest.events[day] = "stay";
      } else {
        dest.events[day] = "departure";
      }
    });
    updateDaysDataObject(dest, destDaysData);
  });

  return destDaysData;
};

var getAccDaysData = function getAccDaysData(details) {
  var accDaysData = {};
  Object.entries(_lodash["default"].get(details, "accommodation_data", {})).forEach(function (entry) {
    var acc = entry[1][0];
    acc["destOrder"] = parseInt(entry[0], 10);
    var dest = details.destinations.find(function (dest) {
      return parseInt(dest.order, 10) === parseInt(entry[0], 10);
    });
    var checkInDay = getStartDay(dest.order, details);
    acc["days"] = setDays(dest.stay, checkInDay);
    acc["events"] = {};
    acc.days.forEach(function (day, idx) {
      if (idx === 0) {
        acc["events"][day] = "check_in";
      } else if (idx > 0 && idx + 1 < acc.days.length) {
        acc["events"][day] = "stay";
      } else {
        acc["events"][day] = "check_out";
      }
    });
    updateDaysDataObject(acc, accDaysData);
  });
  return accDaysData;
};

var getAddOnDaysData = function getAddOnDaysData(details) {
  var data = {};
  Object.entries(_lodash["default"].get(details, "addon_data", {})).forEach(function (entry) {
    data[entry[0]] = entry[1].map(function (ent) {
      return _objectSpread(_objectSpread({}, ent), {}, {
        events: _defineProperty({}, entry[0], "tour")
      });
    });
  });
  return data;
};

var getFlightDaysData = function getFlightDaysData(details, isRoundtrip) {
  var flightDaysData = {};

  var flights = _lodash["default"].get(details, "transportation_data.flights", {});

  Object.entries(flights).forEach(function (entry) {
    var flight = entry[1][0];

    if (entry[0] === "extremalLeg") {
      flight["destOrder"] = 1;
      flight["destData"] = {
        name_en: details.destinations[0].name_en
      };
      flight["days"] = [1];
      flight["events"] = {
        1: "arrival"
      };

      if (isRoundtrip) {
        var lastDay = _lodash["default"].last(Object.keys(details.content.itineraryDaysData));

        flight["days"].push(lastDay);
        flight["events"][lastDay] = "departure";
      }
    } else {
      var dest = details.destinations.find(function (dest) {
        return parseInt(dest.order, 10) === parseInt(entry[0], 10);
      });
      var startDay = getStartDay(dest.order, details);
      flight["destOrder"] = dest.order;
      flight["destData"] = {
        name_en: dest.name_en
      };
      flight["days"] = setDays(dest.stay, startDay);
      flight["days"] = [_lodash["default"].last(flight["days"])];
      flight["events"] = _defineProperty({}, flight["days"][0], "departure");
    }

    updateDaysDataObject(flight, flightDaysData);
  });
  return flightDaysData;
};

var getTransferDaysData = function getTransferDaysData(details) {
  var transferDaysData = {};

  var transfers = _lodash["default"].get(details, "transfer_data", {});

  Object.entries(transfers).forEach(function (entry) {
    var trfs = _lodash["default"].get(entry[1], "inbound_outbound");

    var inbound_trf = null;
    var outbound_trf = null;

    if (trfs) {
      inbound_trf = _lodash["default"].omit(trfs[0], "outbound");
      outbound_trf = _lodash["default"].omit(trfs[0], "inbound");
    } else {
      inbound_trf = trfs.inbound[0];
      outbound_trf = trfs.outbound[0];
    }

    inbound_trf["destOrder"] = entry[0];
    outbound_trf["destOrder"] = entry[0];
    var dest = details.destinations.find(function (dest) {
      return parseInt(dest.order, 10) === parseInt(entry[0], 10);
    });
    var startDay = getStartDay(dest.order, details);
    inbound_trf["destOrder"] = dest.order;
    outbound_trf["destOrder"] = dest.order;
    var days = setDays(dest.stay, startDay);
    inbound_trf["days"] = [days[0]];
    outbound_trf["days"] = [_lodash["default"].last(days)];
    inbound_trf["events"] = _defineProperty({}, days[0], "inbound");
    outbound_trf["events"] = _defineProperty({}, _lodash["default"].last(days), "outbound");
    updateDaysDataObject(inbound_trf, transferDaysData);
    updateDaysDataObject(outbound_trf, transferDaysData);
  });
  return transferDaysData;
};

var dayByDayDataSelector = (0, _reselect.createSelector)([getTourDetails, tourIsRoundtripSelector], function (details, isRoundtrip) {
  if (details.product_type !== "PKG") {
    return null;
  }

  var day_by_day = _lodash["default"].get(details, "content.itineraryDaysData", {});

  var destDaysData = getDestDaysData(details);
  var accDaysData = getAccDaysData(details);
  var addOnDaysData = getAddOnDaysData(details);
  var flightsDaysData = getFlightDaysData(details, isRoundtrip);
  var transferDaysData = getTransferDaysData(details);
  Object.entries(day_by_day).forEach(function (entry) {
    var dayIdx = parseInt(entry[0], 10);
    var dayData = entry[1];
    dayData["accommodations"] = accDaysData[dayIdx];
    dayData["destinations"] = destDaysData[dayIdx];
    dayData["addons"] = addOnDaysData[dayIdx];
    dayData["transfers"] = transferDaysData[dayIdx];
    dayData["flights"] = flightsDaysData[dayIdx];
  });
  return day_by_day;
});
exports.dayByDayDataSelector = dayByDayDataSelector;

var getPkgMetadata = function getPkgMetadata(state) {
  return _lodash["default"].get(state, "WebsiteMetadata.packagesMetadata", {});
};

var getCountriesInfo = function getCountriesInfo(state) {
  return state.CountriesInfo;
};

var topCountriesMetaSelector = (0, _reselect.createSelector)([getPkgMetadata, getCountriesInfo], function (pkgs, countries) {
  var country_codes = _toConsumableArray(new Set(_lodash["default"].flattenDeep(_lodash["default"].get(pkgs, "destinations", []).map(function (dest) {
    return dest.country_code;
  }))));

  var info = {};
  Object.entries(countries).filter(function (entry) {
    return country_codes.includes(entry[0]);
  }).forEach(function (entry) {
    return info[entry[0]] = entry[1];
  });
  return {
    country_codes: country_codes,
    info: info
  };
});
exports.topCountriesMetaSelector = topCountriesMetaSelector;

var getToursAndOrdering = function getToursAndOrdering(state) {
  var _state$ToursList = state.ToursList,
      list = _state$ToursList.list,
      ordering = _state$ToursList.ordering,
      product_type = _state$ToursList.product_type;
  return {
    list: list,
    ordering: ordering,
    product_type: product_type
  };
};

var getToursList = (0, _reselect.createSelector)([getToursAndOrdering], function (tours_and_order) {
  var list = tours_and_order.list,
      ordering = tours_and_order.ordering,
      product_type = tours_and_order.product_type;

  if (product_type === "PKG") {
    return list;
  } else {
    return list.sort(function (a, b) {
      var pricea = a.min_price_from.value;
      var priceb = b.min_price_from.value;

      if (ordering === "price_low_to_high") {
        return pricea - priceb;
      } else if (ordering === "price_high_to_low") {
        return priceb - pricea;
      } else {
        return 0;
      }
    });
  }
});
exports.getToursList = getToursList;
var tripServicesListSelector = (0, _reselect.createSelector)([getTourDetails, tourIsRoundtripSelector, propsInjector], function (tourDetails, isRoundtrip) {
  var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    noDoubleSrvs: false
  };
  var noDoubleSrvs = props.noDoubleSrvs;
  console.log("noDoubleSrvs", noDoubleSrvs);
  var services = [];

  var flights = _lodash["default"].get(tourDetails, "transportation_data.flights", {});

  var originFlight = _lodash["default"].get(flights, "extremalLeg.0");

  services.push({
    service: originFlight,
    event: "departure",
    srvType: "FL"
  });
  var transfers = tourDetails.transfer_data;
  tourDetails.destinations.forEach(function (dest) {
    var dest_order = dest.order,
        name_en = dest.name_en;
    var destObj = {
      name_en: name_en,
      dest_order: dest_order
    };

    var destTransfer = _lodash["default"].get(transfers, "".concat(dest_order, ".inbound_outbound.0"));

    var inboundTransfer, outboundTransfer;
    var trfMeta = {
      srvType: "TRF",
      destination: _objectSpread({}, destObj)
    };

    if (destTransfer) {
      inboundTransfer = _objectSpread({
        service: destTransfer,
        event: "inbound"
      }, trfMeta);
      outboundTransfer = _objectSpread({
        service: destTransfer,
        event: "outbound"
      }, trfMeta);
    } else {
      inboundTransfer = _lodash["default"].get(transfers, "".concat(dest_order, ".inbound.0"));
      outboundTransfer = _lodash["default"].get(transfers, "".concat(dest_order, ".outbound.0"));

      if (inboundTransfer) {
        inboundTransfer = _objectSpread({
          service: inboundTransfer,
          event: "inbound"
        }, trfMeta);
      }

      if (outboundTransfer) {
        outboundTransfer = _objectSpread({
          service: outboundTransfer,
          event: "outbound"
        }, trfMeta);
      }
    }

    var accommodation = _lodash["default"].get(tourDetails.accommodation_data, "".concat(dest_order, ".0"));

    if (accommodation) {
      accommodation = {
        service: accommodation,
        event: "check_in",
        srvType: "ACC",
        destination: _objectSpread({}, destObj)
      };
    }

    var addons = _lodash["default"].flatten(Object.values(_lodash["default"].get(tourDetails, "addon_data", {}))).filter(function (addon) {
      return _lodash["default"].get(addon, "destOrder", 0).toString() === dest_order.toString();
    });

    addons = addons.map(function (addon) {
      return {
        service: addon,
        srvType: "MI",
        event: "tour",
        destination: _objectSpread({}, destObj)
      };
    });

    var departingFlight = _lodash["default"].get(flights, "".concat(dest_order, ".0"));

    if (departingFlight) {
      departingFlight = {
        service: departingFlight,
        event: "departure",
        srvType: "FL"
      };
    }

    if (inboundTransfer) {
      services.push(inboundTransfer);
    }

    if (accommodation) {
      services.push(accommodation);
    }

    if (addons.length) {
      services = [].concat(_toConsumableArray(services), _toConsumableArray(addons));
    }

    if (outboundTransfer) {
      services.push(outboundTransfer);
    }

    if (departingFlight) {
      services.push(departingFlight);
    }
  });

  if (isRoundtrip && !noDoubleSrvs) {
    var returnFlight = _lodash["default"].get(flights, "extremalLeg.0");

    services.push({
      service: returnFlight,
      event: "arrival",
      srvType: "FL"
    });
  }

  return services;
});
exports.tripServicesListSelector = tripServicesListSelector;
var pkgTransfersRequiredSelector = (0, _reselect.createSelector)([getTourDetails], function (details) {
  return _lodash["default"].get(details, "itinerary_setup.requiredServices", []).includes("TF");
});
exports.pkgTransfersRequiredSelector = pkgTransfersRequiredSelector;

var getProductCategories = function getProductCategories(state) {
  var _$get = _lodash["default"].get(state, "WebsiteMetadata.metadata", {}),
      packages = _$get.packages,
      addons = _$get.addons,
      flights = _$get.flights,
      accommodation = _$get.accommodation,
      transfers = _$get.transfers;

  return {
    packages: packages,
    addons: addons,
    flights: flights,
    accommodation: accommodation,
    transfers: transfers
  };
};

var getProductCategoriesSelector = (0, _reselect.createSelector)([getProductCategories], function (categories) {
  var p_types = [];

  if (categories.packages) {
    p_types.push("PKG");
  }

  if (categories.addons) {
    p_types.push("MI");
  }

  if (categories.accommodation) {
    p_types.push("ACC");
  }

  if (categories.transfers) {
    p_types.push("TRF");
  }

  if (categories.flights) {
    p_types.push("FL");
  }

  return p_types;
});
exports.getProductCategoriesSelector = getProductCategoriesSelector;