import { Router, Switch, Route } from "react-router-dom";

import NavBar from "./components/NavBar";
import LandingPage from "./pages/LandingPage";
import TourView from "./pages/TourView";
import Tours from "./pages/Tours";
import "./App.css";
import "../node_modules/rsuite/lib/styles/index.less";
import "./less/base.less";
import Footer from "./components/Footer";
import TermsAndConditions from "./components/TermsAndConditions";
import React from "react";
import ScrollToTop from "./components/common/ScrollToTop";
import history from "./history";
import { MetaFetcherHoc } from "@filodamos/apollo_hydrator/lib/umbilical_hocs";
import { cssVariables } from "./jsssetup";

class App extends React.Component {
  render() {
    const { loading } = this.props;

    const footerLogo =
      "https://go2uk-dark.s3-ap-southeast-1.amazonaws.com/assets/go2uk_logo.png";

    return (
      <Router history={history}>
        <ScrollToTop />
        <div className="App">
          <NavBar />
          <Switch>
            <Route exact path="/">
              <LandingPage loading={loading} />
            </Route>
            <Route path="/tour/:tourReference" exact>
              {({ match }) => (
                <TourView
                  tourReference={match.params.tourReference}
                  productType="MI"
                />
              )}
            </Route>
            <Route path="/tours/" exact>
              <Tours />
            </Route>
            <Route path="/terms-and-conditions/" exact>
              <TermsAndConditions
                background="black"
                baseColor={cssVariables.colors.baseColor}
                titleTextColor={cssVariables.colors.baseColor}
                textColor={cssVariables.colors.lightTextColor}
              />
            </Route>
          </Switch>
          <Footer
            logo={footerLogo}
            background="black"
            baseColor={cssVariables.colors.baseColor}
            textColor={cssVariables.colors.baseColor}
          />
        </div>
      </Router>
    );
  }
}

export default MetaFetcherHoc(App);
