export const cssVariables = {
  normal_gap: ".75rem",
  centerPadding: "0 20vw",
  centerMargin: "0 20vw",
  titleTextShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
  media: {
    smallscreen: "screen and (min-width: 1024px)",
    bigscreen: "screen and (min-width: 1441px)",
    retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
  },
  colors: {
    baseColor: "#BC8333",
    lightTextColor: "#F9F8FD",
    lightBackground: "#F9F8FD",
  },
  fonts: {
    titleFont: "Proxima Nova",
    textFont: "Proxima Nova",
  },
};
