import { Icon, IconButton, Panel } from "rsuite";
import { Link, withRouter } from "react-router-dom";

import React from "react";
import { NavBarHydrator } from "@filodamos/apollo_hydrator/lib/umbilical_hocs";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
    this.handleShowMenu = this.handleShowMenu.bind(this);
    this.handleHideMenu = this.handleHideMenu.bind(this);
  }
  handleShowMenu() {
    this.setState((p) => ({ ...p, showMenu: true }));
  }
  handleHideMenu() {
    this.setState((p) => ({ ...p, showMenu: false }));
  }
  render() {
    const { showMenu } = this.state;
    const { redirectToAddOn } = this.props;

    return (
      <div className="NavBar">
        <img
          className="NavBar__logo"
          alt=""
          src="https://go2uk-dark.s3-ap-southeast-1.amazonaws.com/assets/go2uk_logo.png"
        />
        <div className="NavBar__items">
          <div className="NavBar__items__item NavBar__items__item--mobile-hidden">
            <Icon icon="home" />{" "}
            <span>
              <strong>
                <Link className="custom-link" to="/">
                  Home
                </Link>
              </strong>
            </span>
          </div>
          <div
            className="NavBar__items__item NavBar__items__item--mobile-hidden"
            onClick={() => redirectToAddOn(this.props.history)}
          >
            <Icon icon="map-marker" />{" "}
            <span>
              <strong>Tours</strong>
            </span>
          </div>
          <div className="NavBar__items__item NavBar__items__item--burgermenu">
            <IconButton
              icon={<Icon icon="bars" />}
              appearance="primary"
              onClick={this.handleShowMenu}
            />
          </div>
        </div>
        {showMenu ? (
          <React.Fragment>
            <div className="NavBar__menu-overlay"></div>
            <Panel className="NavBar__menu">
              <div className="NavBar__menu__item" onClick={this.handleHideMenu}>
                <Icon icon="home" size="2x" />{" "}
                <h3>
                  <Link className="custom-link" to="/">
                    Home
                  </Link>
                </h3>
              </div>
              <div
                className="NavBar__menu__item "
                onClick={this.handleHideMenu}
              >
                <Icon icon="map-marker" size="2x" />{" "}
                <h3>
                  <Link className="custom-link" to="/tours/">
                    Tours
                  </Link>
                </h3>
              </div>
              <IconButton
                className="NavBar__menu__closebtn"
                icon={<Icon icon="close" />}
                appearance="primary"
                onClick={this.handleHideMenu}
              >
                <strong>Close</strong>
              </IconButton>
            </Panel>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default NavBarHydrator(withRouter(NavBar));
